import { parseCookies } from 'nookies'
import { ThemeProvider } from 'styled-components'
import { useRouter } from 'next/router'
import NextJSProgress from 'nextjs-progressbar'
import React, { useEffect } from 'react'

import { changeTheme } from '@/store/utilsSlice'
import { GlobalStyle } from '@/styles/GlobalStyle'
import { Navbar } from '@/components/commons/Navbar'
import { useAppDispatch, useAppSelector } from '@/store'
import { useScrollTop } from '@/utils/hook/useScrollTop'
import themes from '@/styles/themes'

import type { MergedMenuItemsAndPages } from '@/models/Menus'

type Props = {
  children: React.ReactNode
  menuVoices: MergedMenuItemsAndPages[]
}

const Layout = ({ children, menuVoices }: Props) => {
  const dispatch = useAppDispatch()
  const { theme } = useAppSelector(state => state.utils)
  const { scrollTop } = useScrollTop()
  const { pathname } = useRouter()

  useEffect(() => {
    const cookie = parseCookies()
    if (cookie['THEME'] !== theme) {
      dispatch(changeTheme(cookie['THEME']))
    }
  }, [dispatch, theme])

  return (
    <ThemeProvider theme={themes[theme]}>
      <GlobalStyle />
      <NextJSProgress
        color={themes[theme].colors.primary[200]}
        startPosition={0.3}
        stopDelayMs={200}
        height={3}
        showOnShallow={true}
      />
      <Navbar
        menuVoices={menuVoices}
        scrolled={pathname.split('/').length > 3 || scrollTop > 0}
        theme={theme}
      />
      {children}
    </ThemeProvider>
  )
}

export default Layout
