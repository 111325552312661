import { useState, useEffect } from 'react'

export const useScrollTop = () => {
  const [scrollTop, setScrollTop] = useState(0)

  useEffect(() => {
    const updatePosition = () => {
      setScrollTop(window.pageYOffset)
    }
    window.addEventListener('scroll', updatePosition)
    updatePosition()
    return () => window.removeEventListener('scroll', updatePosition)
  }, [])

  return {
    scrollTop
  }
}
