import { MdChevronRight, MdKeyboardArrowDown } from 'react-icons/md'
import { useTheme } from 'styled-components'
import React, { useMemo } from 'react'

import { ButtonWithIcon } from '@/components/commons/Button'
import { CallToActionLink } from '@/components/commons/GenericLink'
import { RenditionImage } from '@/components/commons/RenditionImage'
import { mergeAnchorWithLink } from '@/utils/functions'

import type { GenericLinkProps } from '@/components/commons/GenericLink'

const InternalLink = ({
  className,
  icon,
  isMenuItem,
  onClick,
  role,
  showDecorator,
  size,
  ui,
  wagtailLink
}: GenericLinkProps) => {
  const { colors } = useTheme()
  const href = useMemo(
    () => mergeAnchorWithLink(wagtailLink?.pagePath, wagtailLink?.anchor),
    [wagtailLink]
  )

  if (!wagtailLink) return null

  if (role === 'button')
    return (
      <ButtonWithIcon
        as='a'
        className={className || ''}
        href={href}
        onClick={() => onClick && onClick()}
        aria-label={`${wagtailLink.label} button`}
        showDecorator={showDecorator}
        size={size}
        ui={ui}
      >
        <RenditionImage image={icon} />
        {wagtailLink.label}
      </ButtonWithIcon>
    )
  return (
    <CallToActionLink
      className={className || ''}
      href={href}
      onClick={() => onClick && onClick()}
    >
      <RenditionImage image={icon} />
      {wagtailLink.label}
      {showDecorator && (
        <>
          {isMenuItem ? (
            <MdKeyboardArrowDown
              color={colors.secondary[500]}
              role='img'
              size={30}
            />
          ) : (
            <MdChevronRight role='img' />
          )}
        </>
      )}
    </CallToActionLink>
  )
}

export { InternalLink }
