import type { DefaultTheme } from 'styled-components'

const colors = {
  primary: {
    100: '#4c507a',
    200: '#1ac755',
    300: '#f9d91c'
  },
  secondary: {
    0: '#ffc700',
    100: '#2d3262',
    200: '#4f59be',
    300: '#727ce3',
    400: '#16c372',
    500: '#37e572',
    600: '#81efa5',
    700: '#2d4962',
    800: '#4f8fbe',
    900: '#72b4e3'
  },
  tertiary: {
    100: '#6397b8',
    200: '#55ae84',
    300: '#d9dae8',
    400: '#d5e2eb',
    500: '#c8f9d9',
    600: '#ffeba3',
    700: '#fffbed'
  },
  link: {
    0: '#007aff'
  },
  neutrals: {
    0: '#ffffff',
    100: '#f8faff',
    200: '#e6f0f9',
    300: '#e9eaf9',
    400: '#f1f5f9',
    500: '#e5e8eb',
    600: '#a6b0bb',
    700: '#757c94',
    800: '#777b86',
    900: '#323341',
    1000: '#171718'
  },
  system: {
    info: {
      0: '#007aff'
    },
    attention: {
      0: '#ffc700'
    },
    success: {
      0: '#1ac755'
    },
    warning: {
      0: '#ff7a00'
    },
    error: {
      0: '#ba1b23'
    }
  }
}

const theme: DefaultTheme = {
  colors: colors,
  titles: {
    h1: {
      normal: {
        fontSize: '5.625rem',
        fontWeight: 600,
        lineHeight: 1.2
      },
      display: {
        fontSize: '5rem',
        fontWeight: 600,
        lineHeight: 1.19
      }
    },
    h2: {
      normal: {
        fontSize: '3.75rem',
        fontWeight: 600,
        lineHeight: 1.2
      },
      display: {
        fontSize: '5.125rem',
        fontWeight: 400,
        lineHeight: 1.22
      }
    },
    h3: {
      normal: {
        fontSize: '2.5rem',
        fontWeight: 400,
        lineHeight: 1.2
      }
    },
    h4: {
      normal: {
        fontSize: '2rem',
        fontWeight: 600,
        lineHeight: 1.38
      }
    },
    h5: {
      normal: {
        fontSize: '1.5rem',
        fontWeight: 600,
        lineHeight: 1.42
      }
    }
  },
  texts: {
    normal: {
      fontSize: '1.063rem',
      fontWeight: 400,
      lineHeight: 1.65
    },
    normalBold: {
      fontSize: '1.063rem',
      fontWeight: 700,
      lineHeight: 1.65
    },
    big: {
      fontSize: '1.25rem',
      fontWeight: 400,
      lineHeight: 1.6
    },
    bigBold: {
      fontSize: '1.25rem',
      fontWeight: 700,
      lineHeight: 1.7
    },
    small: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.57
    },
    smallBold: {
      fontSize: '0.875rem',
      fontWeight: 700,
      lineHeight: 1.57
    },
    little: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: 1.5
    },
    littleBold: {
      fontSize: '0.75rem',
      fontWeight: 700,
      lineHeight: 1.57
    },
    legal: {
      fontSize: '0.625rem',
      fontWeight: 400,
      lineHeight: 1.4
    },
    legalBold: {
      fontSize: '0.625rem',
      fontWeight: 700,
      lineHeight: 1.4
    }
  },
  cta: {
    primary: {
      normal: {
        borderColor: colors.secondary[200],
        color: colors.neutrals[0],
        background: colors.secondary[200]
      },
      hover: {
        borderColor: colors.secondary[100],
        color: colors.neutrals[0],
        background: colors.secondary[100]
      },
      active: {
        borderColor: colors.secondary[100],
        color: colors.secondary[100],
        background: colors.neutrals[0]
      },
      disabled: {
        borderColor: colors.tertiary[300],
        color: colors.neutrals[700],
        background: colors.tertiary[300]
      }
    },
    primaryOutline: {
      normal: {
        borderColor: colors.secondary[200],
        color: colors.secondary[300],
        background: colors.neutrals[0]
      },
      hover: {
        borderColor: colors.secondary[100],
        color: colors.secondary[100],
        background: colors.neutrals[0]
      },
      active: {
        borderColor: colors.secondary[100],
        color: colors.secondary[100],
        background: colors.neutrals[300]
      },
      disabled: {
        borderColor: colors.tertiary[300],
        color: colors.tertiary[300],
        background: colors.neutrals[0]
      }
    },
    secondary: {
      normal: {
        borderColor: colors.secondary[500],
        color: colors.neutrals[900],
        background: colors.secondary[500]
      },
      hover: {
        borderColor: colors.neutrals[1000],
        color: colors.neutrals[0],
        background: colors.neutrals[1000]
      },
      active: {
        borderColor: colors.neutrals[1000],
        color: colors.neutrals[0],
        background: colors.neutrals[1000]
      },
      disabled: {
        borderColor: colors.tertiary[300],
        color: colors.neutrals[700],
        background: colors.tertiary[300]
      }
    },
    secondaryOutline: {
      normal: {
        borderColor: colors.secondary[500],
        color: colors.secondary[500],
        background: 'transparent'
      },
      hover: {
        borderColor: colors.neutrals[0],
        color: colors.neutrals[0],
        background: 'transparent'
      },
      active: {
        borderColor: colors.secondary[400],
        color: colors.secondary[100],
        background: colors.tertiary[500]
      },
      disabled: {
        borderColor: colors.tertiary[300],
        color: colors.tertiary[300],
        background: 'transparent'
      }
    }
  },
  breakpoint: { md: 768, lg: 1024, xl: 1440 }
}

export { theme }
