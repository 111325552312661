import { Provider } from 'react-redux'
import App from 'next/app'
import Head from 'next/head'
import React, { useEffect } from 'react'
import TagManager from 'react-gtm-module'

import store from '@/store'

import { fetchFlatMenu } from '@/utils/api'
import { mergeMenuItemsAndPages } from '@/utils/menu'
import Layout from '@/components/layout/Base'

import 'keen-slider/keen-slider.min.css'

import type { AppProps, AppContext } from 'next/app'
import type { FlatMenu } from '@/models/Menus'

function MyApp({
  Component,
  pageProps,
  devMode,
  gtmAuth,
  gtmId,
  gtmPreview,
  mainMenuResponse
}: AppProps & {
  devMode: boolean
  gtmAuth: string
  gtmId: string
  gtmPreview: string
  mainMenuResponse?: FlatMenu
}) {
  useEffect(() => {
    !devMode &&
      TagManager.initialize({
        auth: gtmAuth,
        gtmId: gtmId,
        preview: gtmPreview
      })
  }, [devMode, gtmAuth, gtmId, gtmPreview])

  return (
    <>
      <Head>
        <meta name='viewport' content='initial-scale=1.0, width=device-width' />
      </Head>
      <Provider store={store}>
        <Layout menuVoices={mergeMenuItemsAndPages(mainMenuResponse)}>
          <Component {...pageProps} />
        </Layout>
      </Provider>
    </>
  )
}

MyApp.getInitialProps = async (context: AppContext) => {
  const devMode = process?.env?.NODE_ENV !== 'production'
  const gtmAuth = process?.env?.GTM_AUTH as string
  const gtmId = process?.env?.GTM_ID as string
  const gtmPreview = process?.env?.GTM_PREVIEW as string

  const ctx = await App.getInitialProps(context)
  try {
    const { data: mainMenuResponse } = await fetchFlatMenu(
      { serverSide: true },
      'main'
    )
    return { ...ctx, devMode, gtmAuth, gtmId, gtmPreview, mainMenuResponse }
  } catch (error) {
    console.error(error)
    return { ...ctx, devMode, gtmAuth, gtmId, gtmPreview }
  }
}

export default MyApp
