import { theme as lightTheme } from '@/styles/themes/light'

import type { DefaultTheme } from 'styled-components'

const theme: DefaultTheme = {
  ...lightTheme,
  colors: {
    ...lightTheme.colors,
    neutrals: {
      0: '#171718',
      100: '#323341',
      200: '#757c94',
      300: '#777b86',
      400: '#a6b0bb',
      500: '#e5e8eb',
      600: '#f1f5f9',
      700: '#e9eaf9',
      800: '#e6f0f9',
      900: '#f8faff'
    }
  }
}

export { theme }
