import React from 'react'
import styled from 'styled-components'
import Link from 'next/link'

import { ExternalLink } from '@/components/commons/ExternalLink'
import { InternalLink } from '@/components/commons/InternalLink'

import type {
  BaseCallToActionProps,
  WagtailLink,
  WagtailRenditionImage
} from '@/models/Utils'

export type GenericLinkProps = BaseCallToActionProps & {
  icon?: WagtailRenditionImage | null
  isMenuItem?: boolean
  role: 'button' | 'link'
  wagtailLink: WagtailLink
}

const GenericLink = ({
  className,
  icon,
  isMenuItem,
  onClick,
  role,
  showDecorator,
  size,
  ui,
  wagtailLink
}: GenericLinkProps) => {
  if (
    !wagtailLink ||
    (!wagtailLink.anchor && !wagtailLink.pagePath && !wagtailLink.url)
  )
    return null

  return (
    wagtailLink &&
    (wagtailLink.url ? (
      <ExternalLink
        className={className || ''}
        icon={icon}
        onClick={onClick}
        role={role}
        showDecorator={showDecorator}
        size={size}
        ui={ui}
        wagtailLink={wagtailLink}
      />
    ) : (
      <InternalLink
        className={className || ''}
        icon={icon}
        onClick={onClick}
        role={role}
        showDecorator={showDecorator}
        isMenuItem={isMenuItem}
        size={size}
        ui={ui}
        wagtailLink={wagtailLink}
      />
    ))
  )
}

const CallToActionLink = styled(Link)`
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.neutrals[900]};
  transition: all 0.3s ease-in-out;
  font-weight: 600;
  &:hover {
    color: ${({ theme }) => theme.colors.secondary[500]};
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    width: 100%;
  }
`

export { GenericLink, CallToActionLink }
