import { MdArrowForward, MdChevronRight } from 'react-icons/md'
import React, { useState } from 'react'
import styled from 'styled-components'

import type { ReactNode } from 'react'
import type { CallToActionSize, CallToActionUI } from '@/models/Utils'

type Props = {
  children: ReactNode[] | ReactNode
  className?: string
  disabled?: boolean
  href?: string
  onClick?: () => void
  rel?: string
  showDecorator?: boolean
  size?: CallToActionSize
  target?: string
  ui?: CallToActionUI
  as?: 'a'
}

const ButtonWithIcon = ({
  children,
  disabled = false,
  showDecorator = true,
  size = 'normal',
  ui = 'primary',
  as,
  ...props
}: Props) => {
  const [isHover, setIsHover] = useState(false)

  return (
    <Button
      {...props}
      disabled={disabled}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      role='button'
      showDecorator={showDecorator}
      size={size}
      ui={ui}
      as={as}
    >
      {children}
      {showDecorator && (
        <IconContainer haveInteraction={isHover}>
          <MdArrowForward role='img' />
          <MdChevronRight role='img' />
        </IconContainer>
      )}
    </Button>
  )
}

const Button = styled.button<{
  disabled?: boolean
  showDecorator?: boolean
  size: CallToActionSize
  ui: CallToActionUI
}>`
  background-color: ${({ theme, ui }) => theme.cta[ui].normal.background};
  border: 1px solid ${({ theme, ui }) => theme.cta[ui].normal.borderColor};
  border-radius: 100px;
  color: ${({ theme, ui }) => theme.cta[ui].normal.color};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  gap: ${({ size }) => (size === 'normal' ? '4px' : '2px')};
  padding: ${({ showDecorator, size }) =>
    size === 'normal'
      ? `10px ${showDecorator ? '15px 10px 20px' : '20px'}`
      : `4px ${showDecorator ? '6px 4px 8px' : '8px'}`};
  transition: all 0.3s ease-in-out;
  width: fit-content;
  font-weight: 600;
  line-height: 1.25;
  text-decoration: none;
  ${({ disabled, theme, ui }) =>
    disabled &&
    `
    color:${theme.cta[ui].disabled.color};
    border-color:${theme.cta[ui].disabled.borderColor};
    background-color:${theme.cta[ui].disabled.background};
    pointer-events: none;
    user-select: none;
  `}
  &:hover {
    color: ${({ theme, ui }) => theme.cta[ui].hover.color};
    border-color: ${({ theme, ui }) => theme.cta[ui].hover.borderColor};
    background-color: ${({ theme, ui }) => theme.cta[ui].hover.background};
  }
  &:active {
    color: ${({ theme, ui }) => theme.cta[ui].active.color};
    border-color: ${({ theme, ui }) => theme.cta[ui].active.borderColor};
    background-color: ${({ theme, ui }) => theme.cta[ui].active.background};
  }
  & > * {
    margin: unset;
    display: flex;
    align-self: center;
    line-height: 1;
  }
`

const IconContainer = styled.div<{ haveInteraction: boolean }>`
  display: flex;
  position: relative;
  & > * {
    transition: opacity 0.3s ease-in-out;
  }
  & > *:first-child {
    opacity: ${({ haveInteraction }) => (haveInteraction ? '1' : '0')};
    position: ${({ haveInteraction }) =>
      haveInteraction ? 'absolute' : 'static'};
  }
  & > *:last-child {
    opacity: ${({ haveInteraction }) => (haveInteraction ? '0' : '1')};
    position: ${({ haveInteraction }) =>
      haveInteraction ? 'static' : 'absolute'};
  }
`

export { Button, ButtonWithIcon }
