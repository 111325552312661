import { MdChevronRight } from 'react-icons/md'
import React, { useMemo } from 'react'

import { ButtonWithIcon } from '@/components/commons/Button'
import { CallToActionLink } from '@/components/commons/GenericLink'
import { RenditionImage } from '@/components/commons/RenditionImage'
import { mergeAnchorWithLink } from '@/utils/functions'

import type { GenericLinkProps } from '@/components/commons/GenericLink'

const ExternalLink = ({
  className,
  onClick,
  icon,
  role,
  showDecorator,
  size,
  ui,
  wagtailLink
}: GenericLinkProps) => {
  const href = useMemo(
    () => mergeAnchorWithLink(wagtailLink?.url, wagtailLink?.anchor),
    [wagtailLink]
  )

  if (!wagtailLink) return null

  if (role === 'button')
    return (
      <ButtonWithIcon
        as='a'
        className={className || ''}
        href={href}
        onClick={() => onClick && onClick()}
        rel='noreferrer noopener'
        showDecorator={showDecorator}
        size={size}
        aria-label={`${wagtailLink.label} button`}
        target='_blank'
        ui={ui}
      >
        <RenditionImage image={icon} />
        {wagtailLink.label}
      </ButtonWithIcon>
    )
  return (
    <CallToActionLink
      className={className || ''}
      href={href}
      onClick={() => onClick && onClick()}
      rel='noreferrer noopener'
      target='_blank'
    >
      <RenditionImage image={icon} />
      {wagtailLink.label}
      {showDecorator && <MdChevronRight role='img' />}
    </CallToActionLink>
  )
}

export { ExternalLink }
