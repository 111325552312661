import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'

import {
  Open_Sans as OpenSans,
  Hepta_Slab as HeptaSlab
} from '@next/font/google'

const baseFont = OpenSans({
  subsets: ['latin'],
  weight: ['400', '500', '600', '700'],
  style: ['normal', 'italic']
})

const titleFont = HeptaSlab({
  subsets: ['latin'],
  weight: ['400', '500', '600', '700'],
  style: ['normal']
})

export const GlobalStyle = createGlobalStyle`
  ${normalize}

  * {
    box-sizing: border-box;
    scroll-behavior: smooth;
    scroll-padding-top: 83px;
  }

  :root {
    font-size: 1em;
  }

  body {
    font-family: ${baseFont.style.fontFamily};
    font-weight: 400;
    color: ${({ theme }) => theme.colors.neutrals[900]};
  }

  h1,
  h2,
  h3 {
    font-family: ${titleFont.style.fontFamily};
    margin: unset;
  }

  h4,
  h5,
  h6 {
    margin: unset;
    font-family: ${baseFont.style.fontFamily};
  }
`
