import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import styled from 'styled-components'

import { Menu } from '@/components/commons/Menu'
import { Theme } from '@/utils/constants'

import type { MergedMenuItemsAndPages } from '@/models/Menus'

const Navbar = ({
  menuVoices,
  scrolled,
  theme
}: {
  menuVoices: MergedMenuItemsAndPages[]
  scrolled: boolean
  theme: keyof typeof Theme
}) => {
  return (
    <Nav scrolled={scrolled}>
      <Link href={'/'}>
        <Image
          src={`/images/logo-${scrolled ? theme : Theme.dark}.svg`}
          width={139}
          height={60}
          alt={'sito logo'}
        />
      </Link>
      <Menu menuVoices={menuVoices} scrolled={scrolled} />
    </Nav>
  )
}

const Nav = styled.nav<{ scrolled: boolean }>`
  width: 100%;
  background-color: ${({ scrolled, theme }) =>
    scrolled ? theme.colors.neutrals[0] : 'transparent'};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
  padding: ${({ scrolled }) =>
    scrolled ? '10px 38px 10px 50px' : '45px 38px 45px 50px'};
  position: fixed;
  z-index: 100;
  top: 0;
  ${({ scrolled }) =>
    scrolled &&
    'box-shadow: 0px 10px 10px rgba(135, 143, 173, 0.04), 0px 14px 20px rgba(135, 143, 173, 0.16);'};
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    padding: ${({ scrolled }) =>
      scrolled ? '10px 25px 10px 20px' : '30px 25px 30px 20px'};
  }
`

export { Navbar }
