import { load } from 'cheerio'

import {
  ArticleSelectedField,
  BlockKeys,
  CaseStudySelectedField,
  SupportedStaticPageType
} from '@/utils/constants'

import type { ArticleBlock } from '@/models/Blocks'
import type { PageListParams } from '@/models/Api'
import type {
  WagtailArticlePage,
  WagtailPublicationsPage
} from '@/models/Pages'

const convertArticlePageToArticleBlock = (
  page: WagtailArticlePage,
  pagePath: string
): ArticleBlock => {
  return {
    type: BlockKeys.article,
    value: {
      abstract: page.abstract,
      author: page.author,
      categories: page.categories,
      firstPublishedAt: page.meta.firstPublishedAt,
      options: page.options,
      pagePath: pagePath,
      title: page.title
    },
    id: `article_page_id_${page.id}`
  }
}

const getNextPage = (currentPage: string) => {
  if (
    isNaN(Number(currentPage)) ||
    !currentPage ||
    typeof currentPage !== 'string' ||
    Number(currentPage) <= 0
  )
    return '1'
  const nextPage = Number(currentPage) + 1
  return nextPage.toString()
}

const getPrevPage = (currentPage: string) => {
  if (
    isNaN(Number(currentPage)) ||
    !currentPage ||
    typeof currentPage !== 'string' ||
    Number(currentPage) <= 0
  )
    return '1'
  const prevPage = Number(currentPage) - 1
  if (isNaN(prevPage) || prevPage === 0) return '1'
  return prevPage.toString()
}

const getPublicationParams: (
  page: WagtailPublicationsPage,
  queryPage: string
) => PageListParams = (page: WagtailPublicationsPage, queryPage: string) => {
  const params: PageListParams = {
    ['child_of']: page.id,
    limit: page.pageSize,
    offset: (Number(queryPage) - 1) * page.pageSize,
    type: page.childrenType,
    fields: CaseStudySelectedField
  }
  if (
    page.childrenType === SupportedStaticPageType['publications.ArticlePage']
  ) {
    params['exclude_page_path'] = page?.options?.[0]?.value?.pagePath
    params.fields = ArticleSelectedField
  }

  return params
}

const localeCompactDate = (date: string, locale: string): string => {
  if (date === null) return '-'

  return new Date(date).toLocaleDateString(locale, {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  })
}

const mergeAnchorWithLink = (
  url: string | null | undefined,
  anchor: string | undefined
): string => {
  if (!anchor) return `${url}`
  return `${url}${anchor}`
}

const parseHTML = (html: string | unknown): string => {
  if (typeof html !== 'string') return ''
  const parsedHTML = load(html)
  parsedHTML('[data-block-key]').removeAttr('data-block-key')
  return parsedHTML.html()
}

export {
  convertArticlePageToArticleBlock,
  getNextPage,
  getPrevPage,
  getPublicationParams,
  localeCompactDate,
  mergeAnchorWithLink,
  parseHTML
}
