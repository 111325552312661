import React from 'react'
import styled from 'styled-components'

import type { WagtailRenditionImage } from '@/models/Utils'

type Props = {
  className?: string
  id?: string
  image?: WagtailRenditionImage | null
}

const RenditionImage = ({ className, id, image }: Props) => {
  if (!image || !image.url) return null

  const { alt, height, url, width } = image

  return (
    <Image
      alt={alt}
      className={className || ''}
      height={height}
      id={id}
      src={url}
      width={width}
    />
  )
}

const Image = styled.img`
  max-width: 100%;
  height: auto;
`

export { RenditionImage }
