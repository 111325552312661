import { MdClose, MdMenu } from 'react-icons/md'
import Image from 'next/image'
import Link from 'next/link'
import React, { useState } from 'react'
import styled, { useTheme } from 'styled-components'

import { GenericLink } from '@/components/commons/GenericLink'
import { MegaMenu } from '@/components/commons/MegaMenu'
import { MenuItemHandle } from '@/utils/constants'

import type { MergedMenuItemsAndPages } from '@/models/Menus'

const Menu = ({
  menuVoices,
  scrolled
}: {
  menuVoices: MergedMenuItemsAndPages[]
  scrolled: boolean
}) => {
  const { colors } = useTheme()
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false)

  if (menuVoices.length === 0) return null

  return (
    <>
      <SafeArea onClick={() => setShowMobileMenu(true)} aria-label='Open Menu'>
        <MdMenu
          color={scrolled ? colors.neutrals[800] : colors.neutrals[0]}
          role='img'
          size={30}
        />
      </SafeArea>
      <Wrapper scrolled={scrolled} showMobileMenu={showMobileMenu}>
        <MobileHeader scrolled={scrolled}>
          <Link href={'/'} onClick={() => setShowMobileMenu(false)}>
            <Image
              src={`/images/logo-light.svg`}
              width={139}
              height={60}
              alt={'sito logo'}
            />
          </Link>
          <SafeArea
            onClick={() => setShowMobileMenu(false)}
            aria-label='Close Menu'
          >
            <MdClose role='img' size={30} />
          </SafeArea>
        </MobileHeader>
        {menuVoices.map((item, i) =>
          item.child.length !== 0 ? (
            <MegaMenu
              key={i}
              onClick={() => setShowMobileMenu(false)}
              parent={item}
              role={'link'}
            />
          ) : (
            <StyledGenericLink
              key={i}
              onClick={() => setShowMobileMenu(false)}
              role={item.handle === MenuItemHandle.cta ? 'button' : 'link'}
              showDecorator
              isMenuItem
              wagtailLink={item}
            />
          )
        )}
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div<{ scrolled: boolean; showMobileMenu: boolean }>`
  display: flex;
  align-items: center;
  gap: 40px;
  p {
    margin: unset;
  }
  p,
  & > a:not([role='button']),
  & > div > a:not([role='button']) {
    color: ${({ scrolled, theme }) =>
      scrolled ? theme.colors.neutrals[800] : theme.colors.neutrals[0]};
  }
  & > a[role='button'],
  & > div > a[role='button'] {
    transition: all 0.3s ease-in-out;
    color: ${({ scrolled, theme }) =>
      scrolled ? theme.colors.neutrals[0] : theme.colors.neutrals[900]};
    background: ${({ scrolled, theme }) =>
      scrolled ? theme.colors.secondary[200] : theme.colors.secondary[500]};
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    transition: all 0.3s ease-in-out;
    position: absolute;
    top: 0;
    left: ${({ showMobileMenu }) => (showMobileMenu ? '0' : '-100vw')};
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    align-items: flex-start;
    background: ${({ theme }) => theme.colors.neutrals[0]};
    gap: 0;
    overflow-y: auto;
    & > a:not([role='button']) {
      padding: 15px 0;
    }
    p,
    & > a:not([role='button']),
    & > div > a:not([role='button']) {
      color: ${({ theme }) => theme.colors.neutrals[800]};
      display: flex;
      justify-content: space-between;
      line-height: 1.25;
      letter-spacing: 0.1px;
    }
    padding: ${({ scrolled }) =>
      scrolled ? '0 25px 85px 20px' : '0 25px 85px 20px'};
  }
`

const SafeArea = styled.button`
  background: transparent;
  padding: 6px 7px;
  border: 0;
  cursor: pointer;
  @media (min-width: ${({ theme }) => theme.breakpoint.lg}px) {
    display: none;
  }
`

const MobileHeader = styled.div<{ scrolled: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  transition: all 0.3s ease-in-out;
  padding: ${({ scrolled }) => (scrolled ? '10px 0' : '30px 0')};
  @media (min-width: ${({ theme }) => theme.breakpoint.lg}px) {
    display: none;
  }
`

const StyledGenericLink = styled(GenericLink)`
  border: none;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    &[role='button'] {
      margin-top: auto;
      align-self: center;
    }
  }
`

export { Menu }
