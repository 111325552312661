import { Theme } from '@/utils/constants'
import { theme as dark } from '@/styles/themes/dark'
import { theme as light } from '@/styles/themes/light'

const theme = {
  [Theme.light]: light,
  [Theme.dark]: dark
}

export default theme
