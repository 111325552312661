import type {
  FlatMenu,
  MenuItem,
  MenuPage,
  MergedMenuItemsAndPages
} from '@/models/Menus'

export const mergeMenuItemsAndPages = (
  data?: FlatMenu
): MergedMenuItemsAndPages[] => {
  if (!data?.items) return []
  return data.items.reduce(
    (result: MergedMenuItemsAndPages[], item: MenuItem) => {
      result.push({
        anchor: item.anchor,
        child: data.pages.filter(
          p =>
            p.pagePath &&
            item.pagePath &&
            p.pagePath.startsWith(item.pagePath) &&
            p.pagePath !== item.pagePath
        ) as MenuPage[],
        handle: item.handle,
        label: item.label,
        pagePath: item.pagePath,
        url: item.url
      })
      return result
    },
    []
  )
}
