export enum AlignmentChoices {
  'center' = 'center',
  'left' = 'left'
}

export enum BlockKeys {
  'accordion' = 'accordion',
  'advancedimagetext' = 'advancedimagetext',
  'article' = 'article',
  'articles' = 'articles',
  'carousel' = 'carousel',
  'casestudy' = 'casestudy',
  'code' = 'code',
  'coverimage' = 'coverimage',
  'cta' = 'cta',
  'hero' = 'hero',
  'image' = 'image',
  'imagelinktext' = 'imagelinktext',
  'jobpositions' = 'jobpositions',
  'labelvalueimagetext' = 'labelvalueimagetext',
  'multilinecardslist' = 'multilinecardslist',
  'offices' = 'offices',
  'policies' = 'policies',
  'product' = 'product',
  'products' = 'products',
  'section' = 'section',
  'singlecolumncardslist' = 'singlecolumncardslist',
  'socials' = 'socials',
  'tagstext' = 'tagstext',
  'teammember' = 'teammember',
  'teamsmembers' = 'teamsmembers',
  'testimonials' = 'testimonials',
  'text' = 'text'
}

export enum SingleColumnCardItemBlockKeys {
  'gallerycard' = 'gallerycard',
  'singlecolumncard' = 'singlecolumncard'
}

export enum CarouselItemBlockKeys {
  'client' = 'client',
  'technology' = 'technology'
}

export enum JobApplicationType {
  'open' = 'open',
  'spontaneous' = 'spontaneous'
}

export enum LinkTypeValues {
  'simple' = 'simple',
  'primary' = 'primary',
  'secondary' = 'secondary'
}

export enum MenuItemHandle {
  'cta' = 'cta',
  '' = ''
}

export enum MultilineCardItemBlockKeys {
  'multilinecard' = 'multilinecard'
}

export enum SeoImageKeys {
  'ogimage' = 'ogimage',
  'twitterimage' = 'twitterimage'
}

export enum SupportedStaticPageType {
  'commons.GenericPage' = 'commons.GenericPage',
  'home.HomePage' = 'home.HomePage',
  'publications.ArticlePage' = 'publications.ArticlePage',
  'publications.CaseStudyPage' = 'publications.CaseStudyPage'
}

export enum SupportedPublicationIndexPageType {
  'publications.PublicationIndexPage' = 'publications.PublicationIndexPage'
}

export enum Theme {
  light = 'light',
  dark = 'dark'
}

export const ArticleSelectedField = 'categories,title,slug,author,options'

export const CaseStudySelectedField =
  'abstract,client_name,options,position,product_name'

export const SupportedPageTypes = {
  ...SupportedStaticPageType,
  ...SupportedPublicationIndexPageType
}
