import { chunk } from 'lodash'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { useOnClickOutside } from 'usehooks-ts'
import React, { useRef, useState } from 'react'
import styled, { useTheme } from 'styled-components'

import { BlockKeys } from '@/utils/constants'
import { CallToActionLink } from '@/components/commons/GenericLink'
import { InternalLink } from '@/components/commons/InternalLink'

import type { MenuPage, MergedMenuItemsAndPages } from '@/models/Menus'

type Props = {
  onClick: () => void
  parent: MergedMenuItemsAndPages
  role: 'button' | 'link'
}

const MegaMenu = ({ onClick, parent }: Props) => {
  const [showMenu, setShowMenu] = useState<boolean>(false)
  const { colors } = useTheme()
  const ref = useRef(null)

  /* istanbul ignore next */
  useOnClickOutside(ref, () => setShowMenu(false))

  if (!parent) return null

  return (
    <ParentWrapper ref={ref}>
      <CallToActionLink as='p' onClick={() => setShowMenu(prev => !prev)}>
        {parent.label}
        <MdKeyboardArrowDown
          color={colors.secondary[500]}
          role='img'
          size={30}
        />
      </CallToActionLink>
      <ChildWrapper onClick={() => setShowMenu(false)} showMenu={showMenu}>
        {parent.child &&
          chunk(parent.child, 2).map((column: MenuPage[], i: number) => (
            <div key={i}>
              {column.map((child, i) => (
                <InternalLink
                  onClick={onClick}
                  wagtailLink={{
                    anchor: '',
                    label: child.label,
                    pagePath: child.pagePath,
                    url: null
                  }}
                  icon={
                    child?.menuOptions?.find(e => e.type === BlockKeys.image)
                      ?.value
                  }
                  key={i}
                  role={'link'}
                />
              ))}
            </div>
          ))}
      </ChildWrapper>
    </ParentWrapper>
  )
}

const ParentWrapper = styled.div`
  position: relative;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutrals[200]};
    border-top: 1px solid ${({ theme }) => theme.colors.neutrals[200]};
    & > a,
    p {
      padding: 50px 0;
    }
  }
`

const ChildWrapper = styled.div<{ showMenu: boolean }>`
  position: absolute;
  background: ${({ theme }) => theme.colors.neutrals[0]};
  top: 35px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  box-shadow: 0px 2px 8px rgba(46, 56, 77, 0.08),
    0px 20px 32px rgba(46, 56, 77, 0.24);
  display: ${({ showMenu }) => (showMenu ? 'flex' : 'none')};
  padding: 36px 0;
  & > div {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0 48px;
    width: max-content;
  }
  & > div:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.colors.tertiary[300]};
  }
  & > div > a:hover > img {
    filter: saturate(1.5);
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    position: unset;
    transform: none;
    box-shadow: none;
    flex-direction: column;
    padding: 0;
    gap: 32px;
    margin: 0 0 50px;
    & > div {
      padding: 0;
      gap: 32px;
    }
    & > div:not(:last-child) {
      border-right: 0;
    }
  }
`

export { MegaMenu }
