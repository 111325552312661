import { axios, withApiOptions } from '@/utils/api/axios'

import type * as T from '@/models/Api'

export const fetchPageDetail = withApiOptions<
  T.PageDetailResponse,
  [params: T.PageParams]
>(({ baseUrl, headers }, params) => {
  return axios.get(`${baseUrl}/api/pages/find/`, { headers, params })
})

export const fetchPageList = withApiOptions<
  T.PagesResponse,
  [params: T.PageListParams]
>(({ baseUrl, headers }, params) => {
  return axios.get(`${baseUrl}/api/pages/`, { headers, params })
})

export const fetchRedirectPage = withApiOptions<
  T.PageRedirectResponse,
  [params: T.PageRedirectParams]
>(({ baseUrl, headers }, params) => {
  return axios.get(`${baseUrl}/api/commons/redirect/`, { headers, params })
})
